import { differenceInSeconds, parseISO } from 'date-fns';
import { tr } from '@util/tr';
import { ExperiencePriceLabel } from '@interfaces/experience-price-label';
import { ExperiencePriceExtra } from '@interfaces/experience-price-extra';
import { CalendarEvent } from 'angular-calendar/modules/common/calendar-common.module';
import { ReservationDetail } from '../reservation-detail/reservation-detail';
import { VwReservation } from '@interfaces/dws/reservation';
import * as moment from 'moment';

export interface CalendarSchedulingEvent {
  reservationDetails: ReservationDetail;
  id: string;
  startAt: Date;
  endAt: Date;
  allDay: boolean;
  state: string,
  primaryColor: string,
  titleEn: string;
  titleIt: string;
  experienceId: string;
  experienceColor?: string;
  wineryId: string;
  type: string;
  contactFullName: string;
  guestcount01: number;
  guestcount02: number;
  labelGuest01En?: string;
  labelGuest01It?: string;
  labelGuest02En?: string;
  labelGuest02It?: string;
  languageEn: string;
  languageIt: string;
  paid: boolean;
  netTotalCents: number,
  paymentCurrency: string,
  employeeName: string,
  roomName: string,
  createdWithSaas: boolean
  color?: { secondary: string; primary: string };
  category?: string;
  experiencePriceLabels: ExperiencePriceLabel[];
  experiencePriceExtras: ExperiencePriceExtra[];
  calendarTileType?: "single" | "grouped";
  childEvents?: CalendarSchedulingEvent[];
  ids: string[]
  createdAt: Date;
  gift: string;
}

interface StatusBase {
  cssClass: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled';
  icon: string;
  text: string;
  color: string;
}

type StatusDraft = StatusBase & { type: 'draft' };
type StatusWaiting = StatusBase & { type: 'waiting' };
type StatusRejected = StatusBase & { type: 'rejected' };
type StatusConfirmed = StatusBase & { type: 'confirmed' };
type StatusRevoked = StatusBase & { type: 'revoked' };
type StatusCompleted = StatusBase & { type: 'completed' };
type StatusCanceled = StatusBase & { type: 'canceled' };
export type MappedStatus =
  StatusWaiting
  | StatusConfirmed
  | StatusRejected
  | StatusRevoked
  | StatusCompleted
  | StatusCanceled
  | StatusDraft;

export const calendarColor = (type: string, primaryColor: string, state: string): { secondary: string; primary: string } => {
  if (type === 'reservation') {
    switch (state) {
      case 'draft':
        return {
          primary: '#009ADE',
          secondary: '#fff'
        }
      case 'waiting':
        return {
          primary: '#009ADE',
          secondary: '#fff'
        }
      case 'canceled':
      case 'rejected':
        return {
          primary: '#5D5F63',
          secondary: '#fff'
        }
      case 'revoked':
        return {
          primary: '#BA324F',
          secondary: '#fff'
        }
      case 'confirmed':
        return {
          primary: '#fff',
          secondary: '#009ADE'
        }
      case 'mixed':
        return {
          primary: '#fff',
          secondary: '"#33638E"'
        }
      case 'completed':
        return {
          primary: '#fff',
          secondary: '#009ADE'
        }
      default:
        return {
          primary: '#33638E',
          secondary: '#CED8E3'
        }
    }
  } else {
    return {
      primary: '#33638E',
      secondary: '#CED8E3'
    }
  }
}

export const calendarInterval = (event: VwReservation): number => {
  if (event.type === 'appointment') {
    if(event.optionalData?.all_day) {
      // calculate the difference in seconds between the start and end of day
      const start = moment(event.date + "T" + event.time).toDate();
      const end = moment(event.date + "T" + event.time).toDate();
      end.setHours(23, 59, 59, 999);
      return differenceInSeconds(end, start);
    }
    let d = (event.optionalData?.duration || "").split(/days?/);
    let initial = 0;
    if (d.length > 1) {
      initial = parseInt(d[0].trim()) * 24 * 60 * 60;
    }
    d = d.pop();
    return d.split(':').reduce((acc: any, time: any, index: any, array: any) => {
      return acc + parseInt(time) * (array.length - index === 3 ? 60 * 60 : array.length - index === 2 ? 60 : 1);
    }, initial);
  }

  let exp: any = event.experience;
  exp = exp ? exp[0] : null;
  let duration = exp ? exp.duration : '0d 0h 0m';
  let durationArray = duration.split(' ');
  let days = 0, hours = 0, minutes = 0;

  const daysElement = durationArray.find((element: any) => element.includes('d'));
  if (daysElement) {
    days = parseInt(daysElement.replace('d', ''));
  }

  const hoursElement = durationArray.find((element: any) => element.includes('h'));
  if (hoursElement) {
    hours = parseInt(hoursElement.replace('h', ''));
  }

  const minutesElement = durationArray.find((element: any) => element.includes('m'));
  if (minutesElement) {
    minutes = parseInt(minutesElement.replace('m', ''));
  }

  return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
}

export const calendarStatus = (event: VwReservation, override?: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' ): MappedStatus => {

  const iconMapping: { [k: string]: string } = {
    'draft': 'edit-pencil',
    'waiting': 'circled-etc',
    'rejected': 'circled-close',
    'revoked': 'circled-close',
    'confirmed': 'circled-checkmark',
    'completed': 'circled-checkmark',
    'canceled': 'circled-close'
  };

  const statusMapping: { [k: string]: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' } = {
    'draft': 'draft',
    'waiting': 'waiting',
    'confirmed': 'confirmed',
    'revoked': 'revoked',
    'rejected': 'rejected',
    'completed': 'completed',
    'canceled': 'canceled'
  };

  const textMapping: { [k: string]: string } = {
    'draft': tr('Draft'),
    'confirmed': tr('Confirmed'),
    'completed': tr('Completed'),
    'rejected': tr('Rejected'),
    'waiting': tr('On hold'),
    'revoked': tr('Revoked'),
    'canceled': tr('Canceleda')
  };

  const status = override || statusMapping[event.state || 'waiting'] || 'waiting';

  return {
    type: status,
    cssClass: status,
    icon: iconMapping[status],
    text: textMapping[status],
    color: calendarColor(event.type || 'automatic', event.optionalData?.color, event.state || 'draft').primary
  }
}