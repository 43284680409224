import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { TranslateModule } from '@ngx-translate/core';
import { KeycountPipeModule } from "@pipes/keycount/keycount.module";
import { AppointmentDetailModule } from '@web/app/components/appointment-detail/appointment-detail.module';
import { CalendarConfigurationModule } from '@web/app/components/calendar-configuration/calendar-configuration.module';
import { AppointmentModule } from '@web/app/components/dialogs/appointment/appointment.module';
import { ReservationModule } from '@web/app/components/dialogs/reservation/reservation.module';
import { GroupedReservationsDetailModule } from "@web/app/components/grouped-reservations-detail/grouped-reservations-detail.module";
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { ReservationCalendarModule } from '@web/app/components/reservation-calendar/reservation-calendar.module';
import { ReservationDetailModule } from "@web/app/components/reservation-detail/reservation-detail.module";
import { ReservationListModule } from '@web/app/components/reservation-list/reservation-list.module';
import { CalendarModule } from 'angular-calendar';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ReservationSearchBarModule } from '@web/app/components/reservation-search-bar/reservation-search-bar.module';
import { LetDirectiveModule } from '@web/app/directives/let/let.module';
import { DwsPermissionDirectiveModule } from '@util/directives/dwsPermission/dws-permission.module';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatButtonModule,
    CalendarModule,
    MatButtonToggleModule,
    FormsModule,
    TranslateModule,
    LoadingModule,
    ReservationCalendarModule,
    MatIconModule,
    MatDialogModule,
    ReservationModule,
    FlexModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatMenuModule,
    ReservationListModule,
    MatCheckboxModule,
    MatDatepickerModule,
    AppointmentModule,
    AppointmentDetailModule,
    CalendarConfigurationModule,
    KeycountPipeModule,
    MatBadgeModule,
    ReservationDetailModule,
    GroupedReservationsDetailModule,
    ReservationSearchBarModule,
    LetDirectiveModule,
    DwsPermissionDirectiveModule
  ]
})
export class DashboardModule { }