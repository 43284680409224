import { CalendarConfiguration } from "@interfaces/dws/calendar-configuration";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { isEqual } from "date-fns";
import { CalendarEvent, collapseAnimation } from "angular-calendar";
import { locale } from "@util/locale/locale";
import * as _ from "lodash";
import {
  calendarInterval,
  calendarStatus,
} from "@services/dws/scheduling/calendar-event/calendar-scheduling-event";
import { TranslateService } from "@ngx-translate/core";
import { capitalizeEachWord } from "../../../../../../util/transform-string/capitalize-each-word";
import {
  formatLabels,
  formatLabelsFromEvent,
} from "../../../util/calendar-reservation";
import { CurrencyPipe } from "@angular/common";
import { ExperiencePriceLabel } from "@interfaces/experience-price-label";
import { ExperiencePriceExtra } from "@interfaces/experience-price-extra";
import { NgEventBus } from "ng-event-bus";
import { VwReservation } from "@interfaces/dws/reservation";
import { Room } from "@interfaces/dws/room";
import { Employee } from "@interfaces/dws/employee";
import { RoomsService } from "@services/dws/rooms.service";
import { EmployeesService } from "@services/dws/employees.service";
import { AccountsService } from "@services/accounts.service";
import * as moment from "moment";
import { tr } from "@util/tr";

interface Period {
  start: Date;
  end: Date;
}

const elementGroupedColor = { primary: "#FFFFFF", secondary: "#3a5064" };
const elementGroupedAwaitingColor = {
  primary: "#3a5064",
  secondary: "rgba(58, 80, 100, 0.5)",
};
const elementSingleColor = {
  primary: "#FFFFFF",
  secondary: "rgb(0, 154, 222)",
};
const elementSingleAwaitingColor = { primary: "#009ADE", secondary: "#FFFFFF" };
const elementSingleDraftColor = { primary: "#757575", secondary: "#FFFFFF" };
const elementSingleRejectedColor = { primary: "#000000", secondary: "#FFFFFF" };
const elementAppointmentColor = { primary: "#33638E", secondary: "#CED8E3" };
const WAITING_STATE_STRING = "waiting";
const DRAFT_STATE_STRING = "draft";
const COMPLETED_STATE_STRING = "completed";
const CONFIRMED_STATE_STRING = "confirmed";
const REJECTED_STATE_STRING = "rejected";
const REVOKED_STATE_STRING = "revoked";
const CANCELED_STATE_STRING = "canceled";

@Component({
  selector: "app-reservation-calendar",
  templateUrl: "./reservation-calendar.component.html",
  styleUrls: ["./reservation-calendar.component.scss"],
  animations: [collapseAnimation],
})
export class ReservationCalendarComponent {
  locale = locale().locale;
  language = "";
  public capitalizeEachWord = capitalizeEachWord;
  public calendarStatus = calendarStatus;
  public formatLabelsFromEvent = formatLabelsFromEvent;
  public formatLabels = formatLabels;

  @Input() view: "month" | "week" | "day" = "week";
  @Input() date: Date = new Date();
  @Input() calendarConfiguration?: CalendarConfiguration;

  @Input() set calendarSchedulingEvents(events: VwReservation[]) {
    this.events = this.processCalendarSchedulingEvents(events);
    this.eventBus.cast("reservation:newLoaded", {
      status: "refreshed",
      reservations: this.events,
    });
  }

  private period: Period = { start: new Date(), end: new Date() };
  @Output() periodChange: EventEmitter<Period> = new EventEmitter<Period>();
  @Output() eventClick: EventEmitter<CalendarEvent<VwReservation>> =
    new EventEmitter<CalendarEvent<VwReservation>>();

  events: CalendarEvent<VwReservation>[] = [];
  activeDay!: Date;
  activeDayIsOpen!: boolean;
  cPipe: CurrencyPipe;

  rooms: Room[] = [];
  employees: Employee[] = [];
  wineryId?: string;

  interval(displayEvent: any): number {
    return calendarInterval(displayEvent.event.meta as VwReservation);
  }

  reservationMasterContact(event: CalendarEvent<VwReservation>) {
    return event.meta?.reservationContacts?.find(
      (c) => c.contact_type === "MASTER"
    );
  }

  constructor(
    public translate: TranslateService,
    private cpipe: CurrencyPipe,
    private eventBus: NgEventBus,
    private roomsService: RoomsService,
    private employeesService: EmployeesService,
    private accountsService: AccountsService
  ) {
    this.language = translate.getDefaultLang();
    this.cPipe = cpipe;
    this.accountsService.company$.subscribe((company) => {
      this.wineryId = company?.id;
      this.roomsService
        .list(this.wineryId!)
        .subscribe((rooms) => (this.rooms = rooms));
      this.employeesService
        .list(this.wineryId!)
        .subscribe((employees) => (this.employees = employees));
    });
  }

  formatTotal(
    totalCents: number,
    paymentCurrency: string,
    showSymbol: boolean = true
  ): string {
    let money = totalCents / 100;
    if (showSymbol) {
      return this.cPipe.transform(money, paymentCurrency, "code", "1.0-0")!;
    } else {
      return this.cPipe.transform(money, paymentCurrency, "", "1.0-0")!;
    }
  }

  dayClicked(day: any) {
    this.activeDay = day.date;
    this.activeDayIsOpen = day.events && day.events.length > 0;
  }

  calendarPeriodChanged(period: { start: Date; end: Date }) {
    if (
      isEqual(this.period.start, period.start) &&
      isEqual(this.period.end, period.end)
    )
      return;
    this.period = period;
    this.periodChange.emit(period);
  }

  prepareCalendarGroups(
    calendarSchedulingEvents: VwReservation[]
  ): VwReservation[] {
    let calScheEvents = calendarSchedulingEvents;
    let toReturn: VwReservation[] = [];
    let mapGroupsOfEvents = _.groupBy(calScheEvents, (x) => {
      if (
        x.type !== "appointment" &&
        x.state !== DRAFT_STATE_STRING &&
        x.state !== CANCELED_STATE_STRING
      ) {
        return x.experienceId! + x.date! + x.time! + x.languageIso;
      } else {
        return x.id;
      }
    });

    let allSlot = Object.keys(mapGroupsOfEvents);
    allSlot.forEach((slot) => {
      let groupedEvents = mapGroupsOfEvents[slot];
      if (groupedEvents.length === 1) {
        // Single Reservation
        let elem = groupedEvents[0];
        elem.optionalData = elem.optionalData || {};
        elem.optionalData.calendarTileType = "single";
        toReturn.push(elem);
      } else {
        let elems = groupedEvents;

        let mapGroupsRooms = _.groupBy(elems, (x) => {
          if (
            x.state === CONFIRMED_STATE_STRING ||
            x.state === COMPLETED_STATE_STRING ||
            x.state === WAITING_STATE_STRING
          )
            return x.roomId;
          return "";
        });

        let mapGroupsEmployees = _.groupBy(elems, (x) => {
          if (
            x.state === CONFIRMED_STATE_STRING ||
            x.state === COMPLETED_STATE_STRING ||
            x.state === WAITING_STATE_STRING
          )
            return x.employeeId;
          return "";
        });

        let singleEvent = Object.assign({}, groupedEvents[0]);
        singleEvent.optionalData = singleEvent.optionalData || {};
        singleEvent.optionalData.calendarTileType = "grouped";
        // CLEAT SINGLE FIELDS
        singleEvent.reservationContacts = [];
        singleEvent.state = "";
        singleEvent.employeeId = _.join(
          Object.keys(mapGroupsEmployees).filter((x) => !!!!x && x !== "null"),
          ", "
        );
        singleEvent.roomId = _.join(
          Object.keys(mapGroupsRooms).filter((x) => !!!!x && x !== "null"),
          ", "
        );
        // GROUP BY GUESTS
        singleEvent.guestCount01 = _.sumBy(elems, (x) =>
          x.state === CONFIRMED_STATE_STRING ||
          x.state === COMPLETED_STATE_STRING ||
          x.state === WAITING_STATE_STRING
            ? x.guestCount01 || 0
            : 0
        );
        singleEvent.guestCountTotal = _.sumBy(elems, (x) =>
          x.state === CONFIRMED_STATE_STRING ||
          x.state === COMPLETED_STATE_STRING ||
          x.state === WAITING_STATE_STRING
            ? x.guestCountTotal || 0
            : 0
        );
        //singleEvent.guestcount02 = _.sumBy(elems, x => x.state === CONFIRMED_STATE_STRING || x.state === COMPLETED_STATE_STRING || x.state === WAITING_STATE_STRING  ?  x.guestcount02 : 0);
        // JSON
        singleEvent.reservationPriceLabels =
          this.joinExperiencesPriceLabels(elems);
        singleEvent.reservationPriceExtras =
          this.joinExperiencesPriceExtra(elems);
        //
        singleEvent.netTotalCents = _.sumBy(elems, (x) => x.netTotalCents || 0);
        singleEvent.optionalData.childEvents = elems;
        toReturn.push(singleEvent);
      }
    });

    return toReturn;
  }

  joinExperiencesPriceLabels(
    childs: VwReservation[]
  ): ExperiencePriceLabel.PriceLabel[] {
    const mapToFirst = new Map<number, ExperiencePriceLabel.PriceLabel>();

    childs.forEach((x) => {
      if (
        x.state === CONFIRMED_STATE_STRING ||
        x.state === COMPLETED_STATE_STRING ||
        x.state === WAITING_STATE_STRING
      ) {
        let toShiftPositions = !x.reservationPriceLabels?.find((l) => l.islabel1) && x.reservationPriceLabels?.find((l) => l.position === 0);
        x.reservationPriceLabels
          ?.map((label) => toShiftPositions ? { ...label, position: label.position + 1 } : label)
          ?.filter((l) => !l.islabel1)
          .forEach((label) => {
            const cElemId = label.position;
            const savedElem = mapToFirst.get(cElemId);

            if (savedElem === undefined) {
              // ADD
              const labelC: ExperiencePriceLabel.PriceLabel = { ...label };
              mapToFirst.set(cElemId, labelC);
            } else {
              savedElem.price_cents += label.price_cents;
              savedElem.quantity += label.quantity;
            }
          });
      }
    });

    return Array.from(mapToFirst.values());
  }

  joinExperiencesPriceExtra(
    childs: VwReservation[]
  ): ExperiencePriceExtra.PriceExtra[] {
    const mapToFirst = new Map<number, ExperiencePriceExtra.PriceExtra>();

    childs.forEach((x) => {
      if (
        x.state === CONFIRMED_STATE_STRING ||
        x.state === COMPLETED_STATE_STRING ||
        x.state === WAITING_STATE_STRING
      ) {
        x.reservationPriceExtras?.forEach((label) => {
          const cElemPos = label.position;
          const savedElem = mapToFirst.get(cElemPos);

          if (savedElem === undefined) {
            // ADD
            const labelC = { ...label };
            mapToFirst.set(cElemPos, labelC);
          } else {
            savedElem.price_cents += label.price_cents;
            savedElem.quantity += label.quantity;
          }
        });
      }
    });

    return Array.from(mapToFirst.values());
  }

  processCalendarSchedulingEvents(
    calendarSchedulingEvents: VwReservation[]
  ): CalendarEvent<VwReservation>[] {
    if (!calendarSchedulingEvents || !!!calendarSchedulingEvents.length || calendarSchedulingEvents.length <= 0) return [];
    let events = calendarSchedulingEvents.slice(); // clono array para no modificar el original
    events = this.prepareCalendarGroups(events);
    var newEvents = events.reduce((arr, e) => {
      this.mapEventToCalendarEvent(e).forEach((x) => arr.push(x));
      return arr;
    }, [] as CalendarEvent[]);

    // SORT 
    newEvents = newEvents.sort((a, b) => {
      if (a.start < b.start) return -1;
      if (a.start > b.start) return 1;
      return 0;
    });

    return newEvents;
  }

  mapEventToCalendarEvent(e: VwReservation): CalendarEvent<VwReservation>[] {
    let generatedSubEvents = [] as CalendarEvent<VwReservation>[];
    let countInWaiting = this.countInWaiting(
      e.optionalData?.childEvents || [e]
    );
    let countColor = elementSingleColor;
    var title = "";
    var startTimeEvent = moment(e.date + "T" + e.time);
    
    if (e.type === "appointment") {
      countColor = elementAppointmentColor;
      title = e.optionalData?.appointment_title || "";
      if(e.optionalData?.all_day){
        startTimeEvent = moment(e.date + "T00:00:00");
      }
    } else {
      title =  e.experienceTranslations
      ? e.experienceTranslations.find((x) => x.translation_id === "TITLE")
          ?._translations[this.language] : "";
      let color1 = {
        secondary:
          e.optionalData?.calendarTileType === "grouped"
            ? elementGroupedAwaitingColor.secondary
            : elementSingleAwaitingColor.secondary,
        primary:
          e.optionalData?.calendarTileType === "grouped"
            ? elementGroupedAwaitingColor.primary
            : elementSingleAwaitingColor.primary,
      };
      let color2 = {
        secondary:
          e.optionalData?.calendarTileType === "grouped"
            ? elementGroupedColor.secondary
            : elementSingleColor.secondary,
        primary:
          e.optionalData?.calendarTileType === "grouped"
            ? elementGroupedColor.primary
            : elementSingleColor.primary,
      };
      countColor = countInWaiting > 0 ? color1 : color2;
      if (
        (e.optionalData?.calendarTileType === "single" &&
          e.state === REJECTED_STATE_STRING) ||
        e.state === REVOKED_STATE_STRING ||
        e.state === CANCELED_STATE_STRING
      ) {
        countColor = {
          secondary: elementSingleRejectedColor.secondary,
          primary: elementSingleRejectedColor.primary,
        };
      }
      if (
        e.optionalData?.calendarTileType === "single" &&
        e.state === DRAFT_STATE_STRING
      ) {
        countColor = {
          secondary: elementSingleDraftColor.secondary,
          primary: elementSingleDraftColor.primary,
        };
      }
    }
    let zIndex = countInWaiting > 0 ? 99 : "revert-layer";


    let end = moment(e.date + "T" + e.time).add(calendarInterval(e), "seconds");

    if (e.type === "appointment" && end.diff(startTimeEvent, "days") > 1) {
      let nextDay = moment(startTimeEvent).add(1, "days").startOf("day");
      let nextDayEnd = moment(nextDay).endOf("day");

      while (end.isAfter(nextDay)) {
        const eventEnd = moment.min([end, nextDayEnd]);
        const isEndOfDay = eventEnd.isSame(eventEnd.clone().endOf('day'));
        
        generatedSubEvents.push({
          id: e.id,
          start: nextDay.toDate(),
          end: eventEnd.toDate(),
          title: `${isEndOfDay ? tr('All Day') : (nextDay.format("HH:mm") + ' - ' + eventEnd.format("HH:mm"))} - ${title}`,
          allDay: isEndOfDay,
          meta: e,
          color: countColor,
          zIndex: zIndex,
        } as any);

        nextDay = moment(nextDay).add(1, "days");
        nextDayEnd = moment(nextDay).add(1, "days");
      }

      end = moment(startTimeEvent).endOf("day");
      e.optionalData = e.optionalData || {};
      e.optionalData.all_day = startTimeEvent.isSame(moment(startTimeEvent).startOf('day'));
      e.optionalData.multi_day = true;
    }

    return [{
      id: e.id,
      start: startTimeEvent.toDate(),
      end: end.toDate(),
      title: `${ !generatedSubEvents?.length ? '' : (
        startTimeEvent.format("HH:mm") + ' - ' + end.format("HH:mm") + ' - '
      )}${title}`,
      allDay: e.optionalData?.all_day || false,
      meta: e,
      color: countColor,
      zIndex: zIndex,
    }, ...generatedSubEvents] as any;
  }

  countInWaiting(childs: VwReservation[]): number {
    let count = 0;
    if (childs && childs.length > 0) {
      let thisStateElems = childs.filter(
        (x) => x.state === WAITING_STATE_STRING
      );
      return thisStateElems.length;
    }
    return count;
  }

  getGuestOne(event: VwReservation) {
    return `${event.guestCount01}`;
  }

  getGuestTwo(event: VwReservation) {
    return `${(event.guestCountTotal || 0) - (event.guestCount01 || 0)}`;
  }

  eventClicked($event: CalendarEvent<VwReservation>) {
    const meta = $event.meta as VwReservation;
    if (meta) this.eventClick.emit($event);
  }

  hexToRGBA(hex: any, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  tooltipText(event: VwReservation) {
    if (event.type != "appointment") {
      var reservations = "";
      let ids =
        event.optionalData?.childEvents?.map((e: VwReservation) => e.id) || [];
      if (ids && ids.length && ids.length > 1) {
        this.translate
          .get("Reservations")
          .subscribe(
            (res) =>
              (reservations = `<span class="event-total-reservations">${res}: ${ids.length}</span><br/>`)
          );
      }
      const stateText = calendarStatus(event);
      const eventTitle = `<span class="tooltip-event-title"><strong>${capitalizeEachWord(
        this.getExperienceName(event)
      )}</strong></span><br/>`;
      const guestName = this.getMasterContactName(event)
        ? `<span><strong>${capitalizeEachWord(
            this.getMasterContactName(event)
          )}</strong></span><br/>`
        : "";
      const language = event.languageIso
        ? `<i class="icon-global"></i><span>${capitalizeEachWord(
            tr("lang_" + event.languageIso)
          )}</span><br/>`
        : "";
      const totalPrice = `<i class="icon-euro"></i><span>${this.formatTotal(
        event.netTotalCents || 0,
        event.paymentCurrency || "EUR",
        false
      )}</span><br/>`;
      const roomName = event.roomId
        ? `<i class="icon-door"></i><span>${capitalizeEachWord(
            this.roomName(event.roomId)
          )}</span><br/>`
        : "";
      const employeeName = event.employeeId
        ? `<i class="icon-employee"></i><span>${capitalizeEachWord(
            this.employeeName(event.employeeId)
          )}</span><br/>`
        : "";
      const guest1Text = event.guestCount01
        ? `<span>${capitalizeEachWord(this.getLabel1Title(event))}: ${
            event.guestCount01
          }</span><br/>`
        : "";
      const priceLabels =
        this.reservationPriceLabelsExcludeFirstIfPresent(event).length == 0
          ? ""
          : event.reservationPriceLabels?.find((label) => label.quantity > 0)
          ? formatLabels(
              this.reservationPriceLabelsExcludeFirstIfPresent(event),
              this.language,
              "<br/>"
            ) + "<br/>"
          : "";
      const extraLabels = event.reservationPriceExtras?.find(
        (label) => label.quantity > 0
      )
        ? formatLabels(event.reservationPriceExtras, this.language, "<br/>") +
          "<br/>"
        : "";
      let state = "";
      if (event.optionalData?.calendarTileType === "single") {
        state = stateText.text
          ? `<span><strong>${stateText.text.toUpperCase()}</strong></span><br/>`
          : "";
      }
      return `<div>${eventTitle}${reservations}${guestName}${guest1Text}${priceLabels}${extraLabels}${language}${totalPrice}${roomName}${employeeName}${state}</div>`;
    } else {
      return `<div>${event.optionalData?.appointment_title || "-"}</div>`;
    }
  }

  reservationPriceLabelsExcludeFirstIfPresent(reservation: VwReservation) {
    return (
      reservation?.reservationPriceLabels?.filter((l) => l.islabel1 !== true) ||
      []
    );
  }

  getMasterContactName(reservation: VwReservation): string {
    const contact = reservation.reservationContacts?.find(
      (c) => c.contact_type === "MASTER"
    );
    if (!contact) return '';
    if (!contact.first_name && !contact.last_name) return "No name";
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  }

  getExperienceName(reservation: VwReservation): string {
    return (
      reservation.experienceTranslations?.find(
        (t) => t.translation_id == "TITLE"
      )?._translations[this.language] || ""
    );
  }

  getLabel1Title(reservation: VwReservation): string {
    return (
      reservation.experienceTranslations?.find(
        (t) => t.translation_id == "TITLE_PRICE_LABEL_0"
      )?._translations[this.language] || ""
    );
  }

  roomName(roomId: string): string {
    return this.rooms.find((x) => x.id === roomId)?.name || "";
  }

  employeeName(employeeId: string): string {
    const ids = employeeId.split(', ');
    const employees = this.employees.filter(e => ids.includes(e.id) && (e?.firstName || e?.lastName));
    if (employees.length === 0) return '';
    return (employees[0].firstName || '') + ' ' + (employees[0].lastName || '') + (employees.length > 1 ? ' + ' + (employees.length - 1) : '');
  }
}
