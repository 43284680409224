<div mat-dialog-content>
  <mat-toolbar>
    <div class="title">
        <span class="title-ref">Ref.</span>
        <span class="title-id">{{gift?.giftId}}</span>
        <div class="gift elavation2">
            gift <i
                class="icon-gift-box-open"></i>
        </div>
    </div>

      <div *ngIf="gift.redemptionDate != null && !returnToReservation " class="reservation-linked">
          <span (click)="openReservation()" class="link-reservation">{{'Open booking details' | translate}}</span>
      </div>

      <div class="actions">
        <button (click)="openReservation()" *ngIf="gift.redemptionDate != null && returnToReservation"
                mat-icon-button>
            <i class="icon-back"></i>
        </button>
        <button
          [matMenuTriggerFor]="moreOptions"
          mat-icon-button
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button (click)="closeClicked()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
  </mat-toolbar>

  <mat-menu #moreOptions="matMenu" xPosition="before">
    <button
      (click)="getGiftVoucher()"
      mat-menu-item
    >
      <mat-icon>print</mat-icon>
      {{ "Print" | translate }}
    </button>

    <button
      (click)="sendGiftEmail()"
      mat-menu-item
    >
      <mat-icon>mail</mat-icon>
      {{ "Send gift email" | translate }}
    </button>

    <button
      *ngIf="invoiceKeys.length > 0"
      (click)="openBillingData()"
      mat-menu-item
    >
      <mat-icon>receipt_long</mat-icon>
      {{ "Billing Data" | translate }}
    </button>

      <web-external-sync-button
              *ngIf="regCashIntegrated"
              [label]="'Sync with Cash register' | translate"
              [matIconName]="'currency_exchange'"
              [successMessage]="'Cash register synced successfully' | translate"
              [errorMessage]="'Error syncing cash register, please try again' | translate"
              [actionObservable]="cashRegisterSync$"
              [statusObservable]="cashRegisterStatus$"
      ></web-external-sync-button>
  </mat-menu>


  <div class="content-wrapper" [formGroup]="form">

    <div class="field-wrapper first-row">
      <label for="title"><i class="icon-glass"></i> {{'Experience' | translate}}</label>
      <input class="bigger-input" type="text" matInput id="title" formControlName="experienceTitle" readonly>
    </div>

    <div class="content-row">
      <div class="row-contacts">
          <label for="buyer"><i class="icon-contact"></i> {{'Buyer Name' | translate}}</label>
        <div id="buyer">{{buyerName }}</div>
      </div>

      <div class="row-contacts">
          <label for="email-buyer"><i class="icon-envelope"></i> {{'Buyer Email' | translate }}</label>
        <div id="email-buyer">{{gift.email | truncate:[30, '...']}}</div>
      </div>
    </div>

    <div class="content-row highlighted">
      <div class="row-contacts">
          <label for="beneficiary"><i class="icon-contact"></i> {{'Recipient Name' | translate}}</label>
        <div id="beneficiary" class="space-between" [ngClass]="editing == 'beneficiaryName' && 'field-wrapper'">
          <span *ngIf="!(editing == 'beneficiaryName')">
            {{form.value.beneficiaryName}}
          </span>
          <input *ngIf="editing == 'beneficiaryName'" type="text" matInput id="beneficiary-name" formControlName="beneficiaryName" [value]="beneficiaryName">
          <button class="edit" mat-icon-button>
            <i *ngIf="!(editing == 'beneficiaryName')" class="icon-edit" (click)="edit('beneficiaryName')"></i>
            <i *ngIf="editing == 'beneficiaryName'" (click)="edit('beneficiaryName', false)"><mat-icon>close</mat-icon></i>
            <i *ngIf="editing == 'beneficiaryName'" (click)="edit('beneficiaryName', true)"><mat-icon>done</mat-icon></i>
          </button>
        </div>
      </div>

      <div class="row-contacts">
          <label for="email-beneficiary"><i class="icon-envelope"></i> {{'Recipient Email' | translate}}</label>
        <div id="email-beneficiary" class="space-between" [ngClass]="editing == 'beneficiaryEmail' && 'field-wrapper'">
          <span *ngIf="!(editing == 'beneficiaryEmail')">
            {{form.value.beneficiaryEmail | truncate:[19, '...']}}
          </span>
          <input *ngIf="editing == 'beneficiaryEmail'" type="text" matInput id="beneficiary-email" formControlName="beneficiaryEmail" [value]="gift.beneficiaryEmail">
          <button class="edit" mat-icon-button>
            <i *ngIf="!(editing == 'beneficiaryEmail')" class="icon-edit" (click)="edit('beneficiaryEmail')"></i>
            <i *ngIf="editing == 'beneficiaryEmail'" (click)="edit('beneficiaryEmail', false)"><mat-icon>close</mat-icon></i>
            <i *ngIf="editing == 'beneficiaryEmail'" (click)="edit('beneficiaryEmail', true)"><mat-icon>done</mat-icon></i>
          </button>
        </div>
      </div>
    </div>

    <div class="content-row">
      <div class="field-wrapper">
        <label for="purchased-date"><i class="icon-coin"></i> {{'Date of purchase' | translate}}</label>
        <input type="text" matInput id="purchased-date" formControlName="purchasedDate" readonly>
      </div>

      <div class="field-wrapper">
        <label for="origin"><i class="icon-location"></i> {{'Origin' | translate}}</label>
        <input type="text" matInput id="origin" formControlName="origin" readonly>
      </div>
    </div>

    <div class="content-row">
      <div class="field-wrapper">
        <label for="sent-date">
          <i class="icon-arrow-bold-right"></i>
          {{'Sending date' | translate}}
          <web-info-icon-hoverable
            description='{{"By selecting today as the new sending date, the gift email will be sent right after saving." | translate}}'
          ></web-info-icon-hoverable>
        </label>
        <input formControlName="sendingDate" id="sent-date"
               matInput
               readonly
               type="text"
          (dateChange)="dateChanged = true"
          [matDatepicker]="sendingDatePicker"
          [matDatepickerFilter]="validateGiftSendingDate">
        <mat-datepicker-toggle matSuffix [for]="sendingDatePicker" class="pen">
          <button matDatepickerToggleIcon class="edit" mat-icon-button (click)="$event.stopPropagation(); edit('sending-date')">
            <i *ngIf="editing != 'sending-date'" class="icon-edit"></i>
            <i *ngIf="editing == 'sending-date'"><mat-icon>close</mat-icon></i>
          </button>
        </mat-datepicker-toggle>
        <mat-datepicker #sendingDatePicker (closed)="edit('sending-date')"></mat-datepicker>
      </div>

      <div class="field-wrapper">
        <label for="price01"><i class="icon-coin"></i> {{price01label() | truncate:[12, '...'] }}</label>
        <input type="text" matInput id="price01" formControlName="price01" readonly>
      </div>
    </div>

    <div class="content-row" [ngStyle]="{'align-items': formPriceLabelsArray.length > 1 ? 'flex-start' : ''}">
      <div class="field-wrapper">
        <label for="expiration-date"><i class="icon-date-opened"></i> {{'Expiration date' | translate}}</label>
        <input type="text" matInput id="expiration-date" formControlName="expirationDate" readonly>
      </div>

      <div class="field-wrapper" *ngIf="gift.children">
        <label for="price02"><i class="icon-coin"></i> {{price02label() | truncate:[12, '...'] }}</label>
        <input type="text" matInput id="price02" formControlName="price02" readonly>
      </div>

      <div class="content-row" style="flex-direction: column; padding: 0">
        <div class="field-wrapper" *ngFor="let priceLabel of formPriceLabelsArray; let last = last" [formGroup]="priceLabel" style="display: -webkit-box;"
             [ngStyle]="{'padding-bottom': last ? '0' : '20px'}">
          <label for="priceLabel">
            <i class="icon-coin"></i>
            {{ (priceLabel.get('title_translations')?.value['it'] | truncate:[12, '...']) }}
          </label>
          <input type="text" matInput id="priceLabel" formControlName="quantity" readonly>
        </div>
      </div>
    </div>

    <div class="content-row">
      <div class="field-wrapper">
        <label for="redemption-date"><i class="icon-calendar"></i> {{'Reservation date' | translate}}</label>
        <input type="text" matInput id="redemption-date" formControlName="redemptionDate" readonly>
      </div>

      <div class="field-wrapper">
        <label for="status"><i class="icon-gift"></i> {{'Status' | translate}}</label>
        <input type="text" matInput id="status" formControlName="status" readonly>
      </div>
    </div>

    <div class="payment-wrapper">

      <div class="content-row">
        <div class="field-wrapper">
          <label for="discountCode"><i class="icon-tag"></i> {{'Discount code' | translate}}</label>
          <input type="text" matInput id="discountCode" formControlName="discountCode" readonly>
        </div>

        <div class="field-wrapper">
          <label for="discount"><i class="icon-tag"></i> {{'Discount' | translate}}</label>
          <input type="text" matInput id="discount"
                 [value]="(this.gift?.extraData?.coupon!.amountCents) / 100 | currency: gift.amountCurrency: 'symbol':'1.0-2'"
                 readonly>
        </div>
      </div>

      <div class="content-row">
        <div class="field-wrapper">
          <label for="method"><i class="icon-credit-card"></i> {{'Method' | translate}}</label>
          <input type="text" matInput id="method" formControlName="method" readonly>
        </div>

        <div class="field-wrapper">
          <label for="total" class="emphasis"><i class="icon-euro"></i> {{'Total' | translate}}</label>
          <input type="text" matInput id="total"
            [value]="(gift.amountCents / 100) | currency: gift.amountCurrency: 'symbol':'1.0-2'" readonly>
        </div>
      </div>
    </div>

    <div *ngIf="isRedeemed" class="content-row">
      <div class="actions-wrapper buttons-row" >
        <div class="actions-buttons space-between">
          <div>
            <button mat-stroked-button (click)="redeemGiftLink()">{{'Redeem' | translate | uppercase}}</button>
          </div>
          <button [disabled]="isSaveButtonDisabled" class="dws-elevation-z2" mat-flat-button (click)="updateGift()">
            {{'Save' | translate | uppercase}}
          </button>
        </div>
      </div>
    </div>

  </div>
</div>