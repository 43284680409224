import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Login } from '@services/accounts.service';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { CalendarFormatterProvider } from '@web/app/pages/dashboard/calendar-formatter.provider';
import { ReservationsService } from '@services/reservations.service';
import formatISO from 'date-fns/formatISO';
import { Reservation } from '@interfaces/reservation';
import { locale } from '@util/locale/locale';
import { addDays, addMonths, addWeeks, isSameDay, parse, subDays, subMonths, subWeeks } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { ReservationComponent } from '@web/app/components/dialogs/reservation/reservation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarView, DashboardView } from '@interfaces/dashboard';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Appointment } from '@interfaces/appointment';
import { AppointmentsService } from '@services/appointments.service';
import { forkJoin, ReplaySubject } from 'rxjs';
import { AppointmentComponent } from '@web/app/components/dialogs/appointment/appointment.component';
import { NotificationService } from '@services/notification.service';
import { LoadingComponent } from '@web/app/components/loading/loading.component';
import { access } from '@util/route/access';
import { ReservationListComponent } from '@web/app/components/reservation-list/reservation-list.component';
import { ReservationFilters } from '@interfaces/reservation-filters';
import { observe } from '@web/util/loading/loading';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';
import { ReservationDetailService } from '@services/dws/scheduling/reservation-detail/reservation-detail.service';
import { ReservationDetail } from '@services/dws/scheduling/reservation-detail/reservation-detail';
import { CalendarSchedulingEvent } from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';
import { CalendarConfigurationService } from '@services/dws/calendar-configuration/calendar-configuration.service';
import { debounceTime, skipWhile, switchMap } from 'rxjs/operators';
import { ga } from "@util/ga";
import { CalendarConfigurationComponent } from '@web/app/components/calendar-configuration/calendar-configuration.component';
import { CalendarConfiguration } from '@interfaces/dws/calendar-configuration';
import { ManualReservationWebSocketService } from '@services/manual-reservation-ws.service';
import {
  CheckReservationByIdsService, CheckResponse
} from '@services/dws/scheduling/check-reservation/check-reservation-by-ids.service';
import { ManualReservationRequestsListenerService } from '@services/manual-reservation-requests-listener.service';
import { toast, toasty } from "@web/util/toast";
import { tr } from "@util/tr";
import { RequestData } from "@interfaces/request-data";
import { lazyToast } from '@web/util/toast/toast';
import { MatSnackBar } from "@angular/material/snack-bar";
import { GroupedReservationsComponent } from '@web/app/components/dialogs/grouped-reservations/grouped-reservations.component';
import { ReservationExportDialogComponent } from '@web/app/components/dialogs/reservation-export-dialog/reservation-export-dialog.component';
import { SchedulingService } from '@services/dws/scheduling/page-scheduling/page-schedulings.service';
import { ReservationDTO, VwReservation } from '@interfaces/dws/reservation';
import { EmployeesService } from '@services/dws/employees.service';
import { RoomsService } from '@services/dws/rooms.service';

type StatusFilter = {
  [k in StatusKeys]?: boolean;
};
type StatusKeys = 'draft' | 'waiting' | 'confirmed' | 'rejected' | 'completed' | 'canceled' | 'revoked';

const statusValues = (f: StatusFilter): StatusKeys[] => {
  return Object.keys(f).filter((k: string) => f[k as StatusKeys]) as StatusKeys[];
};

declare let gapi: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarFormatterProvider
    }
  ]
})
export class DashboardComponent implements OnInit {

  @ViewChild(ReservationListComponent, { static: false }) reservationComponent!: ReservationListComponent;
  appliedFilters: ReservationFilters | undefined;

  statusFilter: StatusFilter & {searchText?: string} = {
    draft: true,
    confirmed: true,
    waiting: true,
    rejected: false,
    completed: true,
    canceled: false,
    revoked: false
  }

  login: Login;
  splitView: boolean = true;
  events: CalendarEvent<Reservation>[] = [];
  locale = locale().locale;
  reservations: Reservation[] = [];
  appointments: Appointment[] = [];
  calendarSchedulingEvents: VwReservation[] = []
  period!: { start: Date; end: Date };
  period$: ReplaySubject<{ start: Date; end: Date }> = new ReplaySubject<{ start: Date; end: Date }>(1);
  selectedReservation?: ReservationDetail;
  selectedAppointment?: Appointment;
  selectedReservationGroup?: VwReservation;
  rooms: Room[] = [];
  employees: Employee[] = [];
  calendarConfiguration: CalendarConfiguration = { startTime: '08', endTime: '22' };

  openReservationDialogEvent: EventEmitter<CalendarSchedulingEvent> = new EventEmitter<CalendarSchedulingEvent>();


  constructor(
    private reservationsService: ReservationsService,
    private reservationDetailService: ReservationDetailService,
    private dialog: MatDialog,
    private dialog2: MatDialog,
    private dialog3: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentsService: AppointmentsService,
    private notificationService: NotificationService,
    private schedulingService: SchedulingService,
    private checkReservationByIdsService: CheckReservationByIdsService,
    private calendarConfigurationService: CalendarConfigurationService,
    private socket: ManualReservationWebSocketService,
    private manualReservationListenerService: ManualReservationRequestsListenerService,
    private snackBar: MatSnackBar,
    private employeeService: EmployeesService,
    private roomService: RoomsService
  ) {
    this.checkPendingManualReservationRequest();
    this.listenPendingIdsToShowProcessingToast();
    this.listenReservationResultsWSK();
    let firstLoad = true;
    this.login = access(this.route).data('login');
    this.splitView = window.innerWidth > 1800;
    this.fetchCalendarConfiguration();
    this.fetchReservations();
    window.addEventListener('resize', () => {
      this.splitView = window.innerWidth > 1800;
      if (!this.splitView) {
        this.selectedReservation = undefined;
        this.selectedAppointment = undefined;
        this.selectedReservationGroup = undefined;
      }
    });
    route.queryParams.subscribe(p => {
      if (firstLoad) {
        this.statusFilter = {
          confirmed: true,
          draft: true,
          waiting: true,
          rejected: false,
          completed: true,
          canceled: false,
          revoked: false
        };
        const date = new Date();
        this._selectedDate = date;
        this._pickedDate = moment(date);
        this.selectedAppointment = undefined;
        this.selectedReservation = undefined;
        this.selectedReservationGroup = undefined;

      }
      if (p.calendar) this.calendarView = p.calendar;
      if (p.view) this.view = p.view;
      if (p.date && !firstLoad) this.selectedDate = parse(p.date, 'yyyy-MM-dd', new Date());
      firstLoad = false;

      this.calculatePeriodAndFetchData();

    });
    this.adjustQuery().then();
    this.reservationsService.updated$.subscribe(() => {
      this.calculatePeriodAndFetchData();
    });
    this.notificationService.list().subscribe();

    try{
      observe(
        forkJoin([
          this.employeeService.list(this.login.company.id),
          this.roomService.list(this.login.company.id)
        ])
      ).subscribe(([employees, rooms]) => {
        this.employees = employees;
        this.rooms = rooms;
      });
    }catch(e){
      console.error(e);
    }
  }

  private checkPendingManualReservationRequest() {
    const timeoutRequest = 60000;
    const ids = this.manualReservationListenerService.listPendingRequestsObj()!;
    for (const obj of ids) {
      var requestId = obj.requestId;
      var reservationId = obj.reservationId;
      var createdAt = obj.createdAt;
      var createdAtD = new Date(createdAt);
      if ((new Date()).getTime() - createdAtD.getTime() > timeoutRequest) {
        this.checkReservationByIdsService.check([requestId]).subscribe(e => this.forEachPromise(e).then());
      } else {
        // Still waiting for response
      }

    }

  }

  private async forEachPromise(e: CheckResponse) {
    if (e.responses && e.responses.length) {
      for (let request of e.responses) {
        if (request && request.data) {
          await this.showToast(request);
        }
      }
    }
  }

  private async showToast(request: { action?: string; createdAt?: string; data?: any; id?: string; reason?: string; reservationId?: string; stacktrace?: string; status?: string }) {
    const data = request.data;
    const requestData = {
      requestId: request.id,
      experienceTitleIt: data?.experience?.titleIt,
      reservationDate: data?.date,
      reservationTime: data.time,
      requestAction: request.action,
      requestStatus: request?.status,
      requestError: request?.reason,
    } as RequestData

    const status = (data?.status as string) || request.status;
    const requestId = request.data.requestId || request.id || "";
    if (status === "success") {
      this.manualReservationListenerService.removeRequestIdFromListener(requestId);
      if (data?.requestError) console.error("requestId: " + data.requestId, data?.requestError)
      await lazyToast(this.mountRequestMessageResult(requestData), 4000)
    } else {
      if (this.requestIdIsPending(requestId)) {
        this.manualReservationListenerService.removeRequestIdFromListener(requestId);
        if (data?.requestError) console.error("requestId: " + data.requestId, data?.requestError)
        await lazyToast(this.mountRequestMessageResult(requestData), 4000)
      }
    }
  }

  private listenReservationResultsWSK(): void {
    /* Init session and when it's fine, start to listen reservation messages.
    *  When get some message, adjust this to send as toast
    *  Success: Can be viewed by any WS session
    *  Error: Just can be viewed by WS session who request
    *  When is processed notification, we can remove from listener
    * */
    const MAX_RECONNECT_ATTEMPTS = 3;
    const RECONNECT_TIMEOUT = 10000;
    let reconnectAttempts = 0;

    const connect = () => {
      this.socket.initSession()
        .then(() => {
          console.info("Connected to WSK server");
          this.socket.listener()
            .subscribe((requisitionResult: any) => {
              if (requisitionResult && requisitionResult.data) {
                const data = JSON.parse(requisitionResult.data);
                const requestData = this.getRequestData(data);

                if (data?.status === "success") {
                  this.showResultAndRemoveFromListener(requestData);
                } else {
                  if (this.requestIdIsPending(requisitionResult.requestId)) {
                    this.showResultAndRemoveFromListener(requestData);
                  }
                }

                this.reservationsService.updated$.next();
              }
            }, (error) => {
              // Handle disconnection or errors here
              console.warn("Socket connection error:", error);
              if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
                // Attempt to reconnect
                reconnectAttempts++;
                setTimeout(connect, RECONNECT_TIMEOUT); // Retry after 2 seconds
              } else {
                console.warn("Max reconnect attempts reached.");
                // Handle what to do when max reconnect attempts are reached
              }
            })
        })
        .catch((error) => {
          // Handle initialization errors here
          console.warn("Socket initialization error:", error);
          if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
            // Attempt to reconnect
            reconnectAttempts++;
            setTimeout(connect, RECONNECT_TIMEOUT); // Retry after 2 seconds
          } else {
            console.error("Max reconnect attempts reached.");
            // Handle what to do when max reconnect attempts are reached
          }
        });
    };

    // Initial connection attempt
    connect();
  }


  private listenPendingIdsToShowProcessingToast() {
    this.manualReservationListenerService.listenPendingRequests()
      .subscribe(ids => {
        if (ids && ids.length > 0) this.snackBar.open(tr('Processing...'), undefined, { verticalPosition: 'top' })
        else this.snackBar.dismiss();
      })
  }

  private getRequestData(data: any) {
    if (data.requestId) {
      return {
        requestId: data.requestId,
        experienceTitleIt: data?.experienceTitleIt,
        reservationDate: data?.reservation?.data?.date,
        reservationTime: data.time,
        requestAction: data.action,
        requestStatus: data.status,
        requestError: data.errors,
        requestType: "manual"
      } as RequestData;
    } else {
      const hour = (data?.reservation?.startTime?.hour || 0).toString().padStart(2, '0');
      const minute = (data?.reservation?.startTime?.minute || 0).toString().padStart(2, '0');

      const formattedTime = `${hour}:${minute}`;
      return {
        requestId: data.reservationId,
        experienceTitleIt: data?.experienceTitleIt,
        reservationDate: data?.reservation?.date?.year + "-" + data?.reservation?.date?.month + "-" + data?.reservation?.date?.day,
        reservationTime: formattedTime,
        requestAction: data.action,
        requestStatus: data.status,
        requestError: data.errors,
        requestType: "external"
      } as RequestData;
    }

  }

  private showResultAndRemoveFromListener(data: RequestData) {
    if (data) {
      toast(this.mountRequestMessageResult(data)).then();
      this.manualReservationListenerService.removeRequestIdFromListener(data.requestId);
      if (data?.requestError) console.error("requestId: " + data.requestId, data?.requestError)
    }
  }

  private refresh() {
    this.periodSelected(this.period)
  }

  public requestIdIsPending(requestId: string) {
    const pendingRequestIds = this.manualReservationListenerService.listPendingRequestsObj();
    return pendingRequestIds!.find(pendingReservation => pendingReservation.reservationId === requestId || pendingReservation.requestId === requestId);
  }

  private mountRequestMessageResult(requestData: RequestData) {
    const formattedDate = moment(requestData.reservationDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    const message = [tr("Booking for the"), requestData.experienceTitleIt, tr('experience on'), formattedDate, tr('at'),
    requestData.reservationTime, tr(`has been ${requestData.requestAction} with ${requestData.requestStatus}`)]
    return message.join(" ")
  }

  fetchCalendarConfiguration() {
    this.calendarConfigurationService.get(this.login.company.id)
      .subscribe(((data: CalendarConfiguration) => {
        this.calendarConfiguration = data || this.calendarConfiguration
      }));
  }

  ngOnInit(): void {
    this.trackPageView();
  }

  trackPageView() {
    switch (this.view) {
      case 'calendar':
        ga.track('Reservation Calendar');
        break;
      case 'list':
        ga.track('Reservation List');
        break;
      default:
        ga.track('Reservations');
        break;
    }
  }

  _selectedDate: Date = new Date();

  get selectedDate(): Date {
    return this._selectedDate;
  };

  set selectedDate(date: Date) {
    if (isSameDay(date, this._selectedDate)) return;
    this._selectedDate = date;
    this._pickedDate = moment(date);
    this.selectedAppointment = undefined;
    this.selectedReservation = undefined;
    this.adjustQuery().then();
  }

  private _pickedDate!: Moment;

  get pickedDate(): Moment {
    if (!this._pickedDate) this._pickedDate = moment(this.selectedDate);
    return this._pickedDate;
  }

  set pickedDate(v: Moment) {
    this._pickedDate = v;
    this.selectedDate = v.toDate();
  }

  calendarViewBKP: CalendarView = 'week';
  _calendarView: CalendarView = 'week';

  get calendarView(): CalendarView {
    return this._calendarView;
  };

  set calendarView(view: CalendarView) {
    const old = this._calendarView;
    this._calendarView = view;
    if (old !== view) {
      this.adjustQuery().then();
    }
  }

  _view: DashboardView = 'calendar';

  get view(): DashboardView {
    return this._view;
  };

  set view(view: DashboardView) {
    const old = this._view;
    this._view = view;
    if (old !== view) {
      this.adjustQuery().then();
    }
  }

  set loading(v: boolean) {
    LoadingComponent.loading = v;
  }

  statusFilterChanged() {
    if (statusValues(this.statusFilter).length == 0) {
      this.statusFilter = {
        draft: true,
        confirmed: true,
        waiting: true,
        rejected: false,
        completed: true,
        canceled: false,
        revoked: false
      }
    }
    if (this.view == 'calendar') this.adjustQuery().then();
    else this.reservationComponent.filterByStatus(this.statusFilter);

  }

  // function to calculate period based on this.selectedDate and fetch new data
  calculatePeriodAndFetchData(): void {
    if (this.view == 'calendar') {
      let firstDate = this.selectedDate;
      let lastDate = this.selectedDate;
      if (this.calendarView == 'month') {
        const firstDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
        const lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);

        const numberOfFirstDay = firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + (firstDayOfMonth.getDay() == 0 ? -6 : 1)
        const firstDayOfCalendar = new Date(new Date(firstDayOfMonth).setDate(numberOfFirstDay))

        const numberOfLastDay = lastDayOfMonth.getDate() - lastDayOfMonth.getDay() + (lastDayOfMonth.getDay() == 0 ? 0 : 7)
        const lastDayOfCalendar = new Date(new Date(lastDayOfMonth).setDate(numberOfLastDay))

        firstDate = firstDayOfCalendar;
        lastDate = lastDayOfCalendar;

      } else if (this.calendarView == 'week') {
        const first = this.selectedDate.getDate() - this.selectedDate.getDay() + (this.selectedDate.getDay() == 0 ? -6 : 1);
        const last = this.selectedDate.getDate() - this.selectedDate.getDay() + (this.selectedDate.getDay() == 0 ? 0 : 7);

        const firstDayOfWeek = new Date(new Date(this.selectedDate).setDate(first));
        const lastDayOfWeek = new Date(new Date(this.selectedDate).setDate(last));

        firstDate = firstDayOfWeek;
        lastDate = lastDayOfWeek;

      }
      this.periodSelected({ start: firstDate, end: lastDate });
    }
  }

  periodSelected(period: { start: Date; end: Date }): void {
    if (this.view == 'calendar') {
      this.period = period;
      this.period$.next(this.period);
    }
  }

  fetchReservations() {
    this.period$
      .pipe(
        skipWhile(() => {
          return !this.period;
        }),
        debounceTime(100),
        switchMap(() => observe(
          this.schedulingService.list(this.login.company.id, {
            startTime: this.period.start,
            endTime: this.period.end,
            state: statusValues(this.statusFilter)
          })
        ))
      )
      .subscribe(e => {
        this.calendarSchedulingEvents = e.content;
      });
  }

  incrementDateClicked() {
    switch (this.calendarView) {
      case 'day':
        this.selectedDate = addDays(this.selectedDate, 1);
        break;
      case 'week':
        this.selectedDate = addWeeks(this.selectedDate, 1);
        break;
      case 'month':
        this.selectedDate = addMonths(this.selectedDate, 1);
        break;
    }
  }

  decrementDateClicked() {
    switch (this.calendarView) {
      case 'day':
        this.selectedDate = subDays(this.selectedDate, 1);
        break;
      case 'week':
        this.selectedDate = subWeeks(this.selectedDate, 1);
        break;
      case 'month':
        this.selectedDate = subMonths(this.selectedDate, 1);
        break;
    }
  }

  winerySchedulingClicked(ws: VwReservation) {
    if (ws.type === 'appointment') {
      observe(this.appointmentsService.get(ws.id!)).subscribe(a => this.openAppointmentDialog(a));
    } else {
      this.openReservationDialog(ws.id);
    }
  }

  openCalendarConfigurationDialog() {
    const dialog = this.dialog.open(CalendarConfigurationComponent, {
      width: '50%',
      minWidth: 450,
      maxWidth: 450,
      data: {
        calendarConfiguration: this.calendarConfiguration,
        wineryId: this.login.company.id
      }
    });

    dialog.afterClosed().subscribe(() => {
      this.fetchCalendarConfiguration();
    });
  }

  openReservationDialog(reservationId?: string) {
    if (!reservationId) return;
    if (this.reservationsService.isOnWaitingList(reservationId)) {
      toasty(tr('This reservation is being updated. Please wait.'))
        .position('bottom')
        .duration(3000)
        .present();
      return;
    }
    this.dialog.open(ReservationComponent, {
      data: {reservationId},
      minWidth: 1031,
    }).afterClosed().subscribe((newReservation: ReservationDTO | undefined) => {
      if (newReservation) {
        this.patchSelectedReservationGroup(newReservation);
      }
    });
  }

  patchSelectedReservationGroup(newReservation: ReservationDTO) {
    let group = this.calendarSchedulingEvents.find(x => 
      x.id === newReservation.id || x.optionalData?.childEvents?.some((y: ReservationDTO) => y.id === newReservation.id)
    );
    // patching father not needed bc it's referenced in the children
    var indexOfOld = (this.calendarSchedulingEvents || []).map(r => r.id).indexOf(newReservation.id)
    if (indexOfOld >= 0) {
      this.calendarSchedulingEvents.splice(indexOfOld, 1, newReservation);
    }
  }

  replaceChildReservation(reservation: VwReservation, newReservation: ReservationDTO) {
    var calendarTileType = reservation.optionalData?.calendarTileType;
    var childEvents = reservation.optionalData?.childEvents;
    Object.assign(reservation, newReservation);
    if (!!reservation.optionalData) {
      reservation.optionalData.calendarTileType = calendarTileType;
      reservation.optionalData.childEvents = childEvents;
    }
  }

  openReservationDialogNew(reservationToClone?: ReservationDTO) {
    this.dialog.open(ReservationComponent, {
      data: {
        reservationToClone
      },
      minWidth: 1031,
    });
  }

  openTourOperatorDialog(reservationToClone?: ReservationDetail) {
    this.dialog.open(ReservationComponent, {
      data: {
        reservationToClone: reservationToClone,
        isTourOperator: true
      },
      minWidth: 1031,
    });
  }

  async openReservationDialogWithLoadData(res: VwReservation) {
    this.openReservationDialog(res.id)
  }

  openReservationDialogWithLoadDataCloned(id: string) {
    let resToClone = this.calendarSchedulingEvents.find(x => x.id === id)!;
    observe(this.reservationDetailService.getV2(id))
      .subscribe(r => {
        this.openReservationDialogNew(r);
      });
  }


  openGroupedReservationsDialog(reservation?: VwReservation) {
    this.dialog2.open(GroupedReservationsComponent, {
      width: '55%',
      minWidth: 1000,
      maxWidth: 1400,
      data: {
        reservation: reservation,
        dashboardComponent: this,
        employees: this.employees,
        rooms: this.rooms
      }
    });
  }

  openAppointmentDialog(appointment?: Appointment) {
    const dialog = this.dialog.open(AppointmentComponent, {
      minWidth: 480,
      maxWidth: 480,
      data: {
        appointment
      },
      panelClass: 'no-overflow'
    });
    dialog.afterClosed().subscribe(() => {
      this.calculatePeriodAndFetchData();
    });
  }

  viewChanged() {
    if (this.view == 'list') {
      this.view = 'calendar';
      if (this.calendarViewBKP) {
        this.calendarView = this.calendarViewBKP;
      }
    }
    else {
      this.calendarViewBKP = this.calendarView;
      this.calendarView = 'week';
      this.view = 'list';
    }
    this.trackPageView();
  }

  todayClicked() {
    this.selectedDate = new Date();
  }


  openExportDialog(): void {
    this.reservationComponent.openExportDialog();
  }

  openFilterDialog(): void {
    this.reservationComponent.openFilterDialog();
  }

  reservationFilter($event: any): void {
    this.appliedFilters = $event as ReservationFilters;
  }

  private async adjustQuery() {
    await this.router.navigate(['/dashboard'], {
      queryParamsHandling: 'merge',
      queryParams: {
        calendar: this.calendarView,
        date: formatISO(this.selectedDate, { representation: 'date' }),
        view: this.view,
        status: statusValues(this.statusFilter)
      }
    })
  }

  async eventClicked($event: CalendarEvent<VwReservation>) {
    let { id, optionalData, type } = $event.meta!;
    const calendarTileType = optionalData?.calendarTileType;

    if (type !== 'appointment') {
      if (calendarTileType === 'grouped') {
        if (this.splitView && this.calendarView === 'day') {
          this.selectedAppointment = undefined;
          this.selectedReservation = undefined;
          this.selectedReservationGroup = $event.meta;
        } else {
          this.openGroupedReservationsDialog($event.meta)
        }
      } else {
        var isPending = this.requestIdIsPending(id!);
        if (isPending) {
          var mex = this.generatePendingMessage();
          toasty(mex)
            .position('bottom')
            .duration(5000)
            .present();
          return;
        }

        this.openReservationDialog(id);
      }
    } else {
      await observe(this.appointmentsService.get(id!))
        .subscribe(a => {
          if (this.splitView && this.calendarView === 'day') {
            this.selectedReservation = undefined;
            this.selectedReservationGroup = undefined;
            this.selectedAppointment = a;
          } else {
            return this.openAppointmentDialog(a);
          }
        })
    }
  }

  generatePendingMessage() {
    const message_prefix = "reservation_pending_message_";
    const message_indexs = [1, 2, 3, 4, 5];
    const randomIndex = Math.floor(Math.random() * message_indexs.length) + 1;

    return tr(message_prefix + randomIndex);
  }

  detailsGroupedClicked(reservation: VwReservation) {
    this.openReservationDialogWithLoadData(reservation);
  }

  addGroupedClicked() {
    this.openReservationDialogWithLoadDataCloned(this.selectedReservationGroup!.id!);
  }

  refreshData() {
    observe(this.schedulingService.list(this.login.company.id, {
      startTime: this.period.start,
      endTime: this.period.end,
      state: statusValues(this.statusFilter)
    })).subscribe(e => {
      this.calendarSchedulingEvents = e.content;
      toasty(tr('The calendar is updated'))
        .position('bottom')
        .duration(2000)
        .present();
    });
  }

  exportDataOpen() {
    const dialog = this.dialog3.open(ReservationExportDialogComponent, {
      width: '50%',
      minWidth: 400,
      maxWidth: 400,
      data: {
        title: "Print reservation",
        wineryId: this.login.company.id
      }
    })
  }

  openListAndApplySearch(searchText: string) {
    this.statusFilter = { ...this.statusFilter, searchText };
    this.view = 'list';
  }
}