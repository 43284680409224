<div class="add-edit-integration">
  <button (click)="closeClicked()" class="closeBtn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="create-"></div>
  <div style="padding: 20px">
    <div [formGroup]="form" class="integration-create">
      <div class="field-wrapper">
        <div style="padding: 40px 40px 20px 40px">
          <img
            [src]="integration.integration?.imageUrl"
            class="mediumImage"
            mat-card-lg-image
            style="width: unset; height: unset"
          />
        </div>
      </div>
      <div *ngIf="!isEdit">
        <div
          style="
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            padding: 0 40px 20px;
          "
        >
          <div
            style="
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding-bottom: 0.2rem;
            "
          >
            {{ integration.integration?.name }}
          </div>
          {{ "Inserisci qui sotto i dati forniti da " | translate }}
          {{ integration.integration?.name }}
        </div>
      </div>
      <div style="padding: 20px">
        <div
          *ngFor="let control of objectKeys(form.controls)"
          class="field-wrapper"
        >
            <label for="name">
                {{ bestName(control) | translate }}
            </label>
            <input
                    [formControlName]="control"
                    [type]="getRegistryType(control)"
                    id="control"
                    matInput
            />
        </div>
      </div>
    </div>
    <div class="actions-wrapper">
      <button (click)="saveClicked()" class="sBtn" mat-stroked-button>
        {{ "Connect" | translate }}
      </button>
      <button
        (click)="syncClicked()"
        *ngIf="isEdit"
        class="sBtn"
        mat-stroked-button
      >
        {{ "Sync" | translate }}
      </button>
      <button
        (click)="removeClicked()"
        class="rmvBtn"
        *ngIf="isEdit"
        mat-stroked-button
      >
        {{ "Remove" | translate }}
      </button>
    </div>
  </div>
</div>