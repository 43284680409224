import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { toast } from "@web/util/toast";
import { tr } from "@util/tr/tr";
import { ReservationPdfExporterComponent } from "../../reservation-pdf-exporter/reservation-pdf-exporter.component";
import { HostReservationFormOptionsService } from "@services/host-reservation-form-options/host-reservation-form-options.service";
import { ExperienceDWS } from "@interfaces/dws/experience-dws";
import { locale } from "@util/locale";
import * as moment from "moment/moment";

@Component({
  selector: "web-reservation-export-dialog",
  templateUrl: "./reservation-export-dialog.component.html",
  styleUrls: ["./reservation-export-dialog.component.scss"],
})
export class ReservationExportDialogComponent implements OnInit {
  dateToUse: FormGroup;
  title: string;
  wineryId: string;

  @ViewChild("exporterPDFPop", { static: false })
  private exporterPDFPop!: ReservationPdfExporterComponent;

  experiences: ExperienceDWS[] = [];
  filteredOptions: ExperienceDWS[] = [];
  selectedReservation: ExperienceDWS | undefined;
  experienceIdForm: FormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<ReservationExportDialogComponent>,
    private hostReservationFormOptionsService: HostReservationFormOptionsService,
    private fb: FormBuilder
  ) {
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    this.title = data.title;
    this.wineryId = data.wineryId;
    this.experienceIdForm = new FormControl("");
    this.dateToUse = new FormGroup({
      start: new FormControl(currentDate, Validators.required),
      end: new FormControl(currentDate, Validators.required),
      startTime: new FormControl('00:00'),
      endTime: new FormControl('23:59'),
    },{ validators: [this.dateRangeValidator , this.timeRangeValidator]});
    hostReservationFormOptionsService
      .get(this.wineryId, "")
      .subscribe((data) => {
        // TODO FILTER AND ORDER
        this.experiences = this.filterReservations(data.experiences);
      });
  }

  displayReservation(experience: ExperienceDWS): string {
    return (
      experience.details.titles.find(
        (x) => x.languageIsoCode.toLowerCase() == locale().locale
      )?.text || "---"
    );
  }

  get lang() {
    return locale().locale;
  }

  filterReservations(data: ExperienceDWS[]): ExperienceDWS[] {
    return data
      .filter((r) => r.details.active === true)
      .sort((a, b) => {
        if (a.details.priority === null && b.details.priority === null) {
          return a.details.titles.find(
            (x) => x.languageIsoCode.toLowerCase() == this.lang
          ).text >
            b.details.titles.find(
              (x) => x.languageIsoCode.toLowerCase() == this.lang
            ).text
            ? 1
            : -1;
        } else if (a.details.priority === null) {
          return 1;
        } else if (b.details.priority === null) {
          return -1;
        } else {
          return a.details.priority! > b.details.priority! ? 1 : -1;
        }
      });
  }

  ngOnInit() {}
  

  filterExperiences() {
    const searchText = this.experienceIdForm.value;
    const filteredExperiences = this.experiences.filter((experience) => {
      const containsSearchText = experience.details.titles
        .find((x) => x.languageIsoCode.toLowerCase() == this.lang)
        .text.toLowerCase()
        .includes(searchText.toLowerCase());

      return containsSearchText;
    });

    this.filteredOptions = filteredExperiences;
  }

  closeClicked() {
    this.dlg.close();
  }

  export(type: "pdf") {
    if (this.dateToUse.invalid) {
      toast(tr("Invalid range date")).then();
      return;
    }

    var experienceId = this.experienceIdForm.value
      ? this.experienceIdForm.value.id
      : undefined;
    const v = this.dateToUse.getRawValue();
    const start = moment(v.start);
    const end = moment(v.end); ;
    if (v.startTime && v.endTime) {
      let [sh, sm] = v.startTime.split(":").map(Number);
      let [eh, em] = v.endTime.split(":").map(Number);

      start.set({ hour: sh, minute: sm, seconds:0 });
      end.set({ hour: eh, minute: em, seconds:0 });

      this.exporterPDFPop.downloadAsPDF(
        start.toDate(),
        end.toDate(),
        experienceId,
        this.wineryId
      );
    } else {

      start.set({ hour: 0, minute: 0, seconds:0 });
      end.set({ hour: 23, minute: 59, seconds:0 });

      this.exporterPDFPop.downloadAsPDF(
        start.toDate(),
        end.toDate(),
        experienceId,
        this.wineryId
      );
    }
    //this.dlg.close({ start, end, type });
  }

  exportPDFClicked() {
    this.export("pdf");
  }

  dateRangeValidator(formGroup: AbstractControl): { [key: string]: any } | null {
    const start = formGroup.get('start')?.value;
    const end = formGroup.get('end')?.value;

    if (start && end && new Date(start) > new Date(end)) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
  
      return { dateRangeInvalid: true };
    }
    return null;
  }

  timeRangeValidator(formGroup: AbstractControl): {[key:string]:any}|null{
    const start = formGroup.get('startTime')?.value;
    const end = formGroup.get('endTime')?.value;
    if(start && end){
      const startTime = moment(start, "HH:mm");
      const endTime = moment(end, "HH:mm");
      if (startTime.isSameOrAfter(endTime)) {
        return { timeRangeInvalid: true };
      }
    }
    return null;
  }
}

