import { Injectable } from "@angular/core";
import { DWSApiKey } from "@interfaces/dws/integration";
import { resource } from "@util/resource";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiKeysService {
  constructor() {}

  activate(apiKey: DWSApiKey): Observable<String> {
    return resource("crm://api-keys/active").put<String>({
      ...apiKey,
    });
  }

  deactivate(apiKey: DWSApiKey): Observable<String> {
    return resource("crm://api-keys/deactivate").put<String>({
      ...apiKey,
    });
  }

  delete(apiKey: DWSApiKey): Observable<String> {
    return resource("crm://api-keys/delete").post<String>({
      ...apiKey,
    });
  }

  create(apiKey: DWSApiKey): Observable<String> {
    return resource("crm://api-keys/create").post<String>(apiKey);
  }

  update(apiKey: DWSApiKey): Observable<String> {
    return resource("crm://api-keys/update").put<String>({
      ...apiKey,
    });
  }

  list(): Observable<DWSApiKey[]> {
    return resource("crm://api-keys").get<DWSApiKey[]>();
  }

  getSingleApiKey(apiKey: String): Observable<DWSApiKey> {
    return resource("crm://api-keys/apiKey").params({ apiKey: apiKey }).get<DWSApiKey>();
  }

  generateKeys(): Observable<any> {
    return resource("crm://api-keys/generate-keys").get<any>();
  }
}
