import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Appointment } from '@interfaces/appointment';
import * as moment from 'moment';
import { Moment } from 'moment';
import { toast } from '@web/util/toast';
import { tr } from '@util/tr';
import { AppointmentsService } from '@services/appointments.service';
import { Client } from '@interfaces/client';
import { MatSidenav } from '@angular/material/sidenav';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { delay, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { HostClientsService } from '@services/host-clients.service';
import { confirm } from '@web/app/components/dialogs/confirm';
import { AccountsService, Login } from '@services/accounts.service';
import { ContactsService, ExternalCalendarSyncService } from '@services/dws';
import { Contact } from '@interfaces/dws/contact';
import { DWS } from '@interfaces/dws';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Employee } from '@interfaces/dws/employee';
import { EmployeesService } from '@services/dws/employees.service';

marker('Fill the email or phone field.');

@Component({
  selector: 'web-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss'],
})
export class AppointmentDetailComponent implements OnInit {
  form: FormGroup;
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() appointment?: Appointment;
  loading: boolean = false;
  login!: Login;

  searchControl: FormControl = new FormControl();
  clientForm: FormGroup;
  clientSaveCallback: (client: Client) => void = () => {
  };
  @ViewChild('clientSideNav') clientSideNav!: MatSidenav;

  @ViewChild('trigger') trigger: any = MatAutocompleteTrigger;
  loadingClients: boolean = false;
  contacts: Contact[] = [];
  contacts$: Observable<DWS.Page<DWS.Contact>>;
  now: Moment = moment();
  contact: Contact = {};

  isSyncingInBackground: boolean = false;

  private startDateTime?: Moment;
  private endDateTime?: Moment;

  employees: Employee[] = [];

  set startDate(v: Moment) {
    this.startDateTime = v;
    // set time again
    if (this.startTime) {
      this.startTime = this.startTime.slice()
    }
    this.endDateTime = undefined;
    this._endTime = '';
    this.form.patchValue({ endTime: '' });
    this.cd.detectChanges();
  }

  get startDate(): Moment {
    return this.startDateTime as Moment;
  }

  set endDate(v: Moment) {
    this.endDateTime = v;
    // set time again
    if (this.endTime) {
      this.endTime = this.endTime.slice()
    }
  }

  get endDate(): Moment {
    return this.endDateTime as Moment;
  }

  private _startTime: string = '';
  set startTime(v: string) {
    this._startTime = v;
    const [hours, minutes] = this._startTime.split(':').map(x => parseInt(x));
    this.startDateTime!.utc(true).hours(hours);
    this.startDateTime!.utc(true).minutes(minutes);
    this.form.patchValue({
      startTime: this.startDateTime?.toISOString()
    })
  }

  get startTime(): string {
    return this._startTime;
  }

  private _endTime: string = '';
  set endTime(v: string) {
    this._endTime = v;
    const [hours, minutes] = this._endTime.split(':').map(x => parseInt(x));
    this.endDateTime!.utc(true).hours(hours);
    this.endDateTime!.utc(true).minutes(minutes);
    this.form.patchValue({
      endTime: this.endDateTime?.utc(false).toISOString()
    })
  }

  get endTime(): string {
    return this._endTime;
  }

  constructor(
    private fb: FormBuilder,
    private appointmentsService: AppointmentsService,
    private cd: ChangeDetectorRef,
    private hostClients: HostClientsService,
    private accountsService: AccountsService,
    private contactsService: ContactsService,
    private externalCalendarSyncService: ExternalCalendarSyncService,
    private employeesService: EmployeesService,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      place: [''],
      allDay: [false, Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      firstname: [''],
      lastname: [''],
      phone: [''],
      email: [''],
      clientSearch: [''],
      notes: [''],
      employeeId: [''],
    })

    this.accountsService.getLogin().then((login) => {
      this.login = login!;
    })

    const startTimeControl = this.form.get('startTime')!;
    const allDayControl = this.form.get('allDay')!;

    allDayControl.disable();

    startTimeControl.valueChanges.subscribe(_ => {
      allDayControl.enable();
    })

    this.form.get('allDay')!.valueChanges.subscribe((all_day) => {
      const endTimeControl = this.form.get('endTime')!;
      if (all_day) {
        endTimeControl.clearValidators();
        endTimeControl.patchValue('');
      } else {
        endTimeControl.setValidators([Validators.required])
      }
    })

    this.clientForm = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['']
    })

    this.contacts$ = this
      .searchControl
      .valueChanges
      .pipe(
        startWith(''),
        filter((v: string | any | null) => {
          return typeof v === 'string' && !!v && v.trim() !== ''
        }),
        tap(() => {
          this.contacts = [];
          this.loadingClients = true;
        }),
        switchMap(v => this.contactsService.list({
          wineryIds: [this.login.company.id],
          q: v,
          includeUnsubscribed: false,
          includeSubscribed: false
        }))
      )

    this.contacts$.subscribe(c => {
      this.contacts = c.content;
      this.loadingClients = false;
    });
  }

  ngOnInit() {
    this.employeesService.list(this.login.company.id).subscribe(employees => {
      this.employees = employees;
    });
    if (this.appointment) {
      this.form.patchValue(this.appointment);
      this.startDate = moment(this.appointment.startTime);
      this.startTime = this.startDate.format('HH:mm');
      if (!this.appointment.allDay) {
        this.endDate = moment(this.appointment.endTime);
        this.endTime = this.endDate.format('HH:mm');
      }
      this.form.patchValue({
        notes: this.appointment.notes,
        employeeId: this.appointment.employeeId,
      });
    }
  }

  syncAppointmentClicked() {
    this.isSyncingInBackground = true;
    this.externalCalendarSyncService.sync(this.appointment?.id!).pipe(delay(500)).subscribe(
      () => { toast(tr('Synchronized successfully')); this.isSyncingInBackground = false; },
      (e) => { toast(tr('An error occurred. Try later')); this.isSyncingInBackground = false; }
    );
  }

  cancelClicked() {
    this.closeClick.emit();
  }

  saveClicked() {
    if (this.endDateTime?.isSameOrBefore(this.startDateTime)) {
      toast('End time cannot be before start time').then();
      return;
    }

    if (!this.appointment) this.createAppointment();
    else this.updateAppointment();
  }

  createAppointment() {
    delete this.form.value.client;
    delete this.form.value.clientSearch;
    const finalValue = {
      ...this.form.value,
      winery: { id: this.login.company.id },
      notes: this.form.value.notes,
      employee: { id: this.form.value.employee },
    };
    this.loading = true;
    this.appointmentsService.create(finalValue)
      .subscribe(() => {
        toast(`${tr('Appointment created.')}`).then();
        this.closeClick.emit();
      }, () => {
        toast(`${tr('There was a problem saving the appointment. Try again later.')}`).then();
      })
      .add(() => this.loading = false);
  }

  updateAppointment() {
    if (!this.appointment) return;
    const updatedValue = {
      ...this.form.value,
      winery: { id: this.login.company.id },
      notes: this.form.value.notes,
      employee: { id: this.form.value.employee },
    };
    this.loading = true;
    this.appointmentsService.update(this.appointment.id as string, updatedValue)
      .subscribe(() => {
        toast(`${tr('Appointment updated.')}`).then();
        this.closeClick.emit();
      }, () => {
        toast(`${tr('There was a problem updating the appointment. Try again later.')}`).then();
      })
      .add(() => this.loading = false);
  }

  patchContact(contact: Contact) {
    this.contact = contact;
    this.form.patchValue({
      firstname: contact.firstName,
      lastname: contact.lastName,
      email: contact.email,
      phone: contact.phone
    })
  }

  patchClient(client: any) {
    this.form.patchValue({
      ...client
    })
  }

  addNewClientClicked() {
    this.addClientRequested({ callback: (client: Client) => this.clientAdded(client), client: undefined });
    setTimeout(() => this.trigger.closePanel());
  }

  addClientRequested(data: { client?: Client, callback: (client: Client) => void }) {
    this.clientSaveCallback = data.callback;
    if (data.client) this.clientForm.patchValue(data.client)
    this.clientSideNav.open();
  }

  clientAdded(client: Client) {
    this.patchClient(client);
  }

  showClientList() {
    setTimeout(() => this.trigger.openPanel());
  }

  clientCloseClicked($event: any) {
    if ($event) {
      this.patchContact($event);
    }
    this.contacts = [];
    this.clientSideNav.close();
  }

  removeClientClicked() {
    this.contact = {};
    this.contacts = [];
    this.form.controls.clientSearch.reset();
    this.form.controls.lastname.reset();
    this.form.controls.firstname.reset();
    this.form.controls.email.reset();
    this.form.controls.phone.reset();
    this.searchControl.reset();
  }

  editClientClicked() {
    this.addClientRequested({
      callback: (client: Client) => this.clientAdded(client),
      client: this.form.value
    });
  }

  async deleteAppointmentClicked() {
    if (!this.appointment) return;
    const confirmed = await confirm.yesno(tr('Are you sure you want to remove this appointment?'));
    if (!confirmed) return;
    this.loading = true;
    this.appointmentsService.remove(this.appointment.id as string)
      .subscribe(() => {
        toast(`${tr('Appointment removed.')}`).then();
        this.closeClick.emit();
      }, () => {
        toast(`${tr('There was a problem removing the appointment. Try again later.')}`).then();
      })
      .add(() => this.loading = false);
  }
}
