export interface IntegrationSyncMetadata {
  wineryId: string; // UUID represented as a string
  integrationId: string; // UUID represented as a string
  entityType: IntegrationEntityTypes;
  entityId: string;
  integrationSyncMetadataId: string;
  metadata: Record<string, any>; // Map<String, Object> equivalent in TypeScript
}

export enum IntegrationEntityTypes {
  WINERY = 'WINERY',
  EXPERIENCE = 'EXPERIENCE',
  RESERVATION = 'RESERVATION',
  PRODUCT = 'PRODUCT',
  CONTACT = 'CONTACT',
  ORDER = 'ORDER',
  GIFT = 'GIFT',
  PAYMENT = 'PAYMENT',
  PREORDER = 'PREORDER',
  STOCK = 'STOCK',
}

export interface IntegrationRemoteStatus {
  status: IntegrationObjRemoteStatus;
  lastUpdated: Date;
  fullMessage: string;
  originalStatus: string;
}

export enum IntegrationObjRemoteStatus {
  SENT = "SENT",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PROCESSING = "PROCESSING",
  PAID = "PAID",
  SHIPPED = "SHIPPED",
  DELIVERED = "DELIVERED",
  CANCELED = "CANCELED",
  NONE = "NONE"
}