import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class ContactWineriesService {

  constructor() {
  }

  create(contactWinerie: DWS.ContactWinery.New): Observable<DWS.ContactWinery> {
    return resource('crm://contacts-wineries')
      .post<DWS.ContactWinery>(contactWinerie);
  }

  list(filters?: ContactWineriesFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.ContactWinery>> {
    return resource('crm://contacts-wineries')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.ContactWinery>>();
  }

  update(contactId: number, wineryId: string, contactWinerie: DWS.ContactWinery.New) {
    return resource('crm://contacts-wineries/contactId/wineryId')
      .params({ contactId, wineryId })
      .put(contactWinerie)
  }

  unsubscribe(contactId: number, wineryId: string) {
    return resource('crm://contacts-wineries/contactId/wineryId/unsubscribe')
      .params({ contactId, wineryId }).put()
  }

  archive(contactId: number, wineryId: string) {
    return resource('crm://contacts-wineries/contactId/wineryId/archive')
      .params({ contactId, wineryId }).post()
  }

  unarchive(contactId: number, wineryId: string) {
    return resource('crm://contacts-wineries/contactId/wineryId/unarchive')
      .params({ contactId, wineryId }).post()
  }
}

export interface ContactWineriesFilters {
  contactId?: number;
  wineryId?: string;
}
