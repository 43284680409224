<button
  (click)="syncClicked($event)"
  mat-menu-item
  class="mat-menu-item"
  [disabled]="!!status && statusObservable"
>
  <mat-icon *ngIf="!isSyncing || statusObservable">{{matIconName}}</mat-icon>
  <mat-icon *ngIf="isSyncing && !statusObservable" class="spinning">sync</mat-icon>
  {{ label }}
  <ng-container *ngIf="statusObservable">
    <mat-icon *ngIf="isSyncing" class="statusIcon spinning">sync</mat-icon>
      <mat-icon *ngIf="!!!!status && status && !isSyncing" class="statusIcon green material-icons-outlined">
          check_circle
      </mat-icon>
      <mat-icon *ngIf="(!!!status || !status) && !isSyncing" class="statusIcon red material-icons-outlined">error
      </mat-icon>
  </ng-container>
</button>