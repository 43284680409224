<mat-toolbar>
  <div class="appointment-title" *ngIf="appointment">{{appointment?.title}}</div>
  <div class="appointment-title" *ngIf="!appointment">{{'New appointment' | translate}}</div>

  <div class="actions">
    <button mat-icon-button (click)="syncAppointmentClicked()" matTooltip="{{'Sync external calendar' | translate}}" [ngClass]="{spinning: isSyncingInBackground}"
            *ngIf="appointment">
      <mat-icon>sync</mat-icon>
    </button>
    <button mat-icon-button (click)="deleteAppointmentClicked()" *ngIf="appointment">
      <i class="icon-trash"></i>
    </button>
    <button mat-icon-button (click)="cancelClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-sidenav [fixedInViewport]="true" #clientSideNav position="end">
    <app-new-contact-sidenav
            [wineryId]="login?.company?.id"
            [contact]="contact"
            [isAppointment]="true"
            (close)="clientCloseClicked($event)">
    </app-new-contact-sidenav>
</mat-sidenav>


<div class="add-appointment-wrapper" [formGroup]="form">
  <div class="add-appointment-fields-wrapper">
    <div class="field-combo">
      <div class="field-wrapper">
        <label for="title">
          {{'Title' | translate}}
        </label>
        <input matInput id="title" type="text" formControlName="title">
      </div>

      <div class="field-wrapper">
        <label for="place">
          {{'Place' | translate}}
        </label>
        <input matInput id="place" type="text" formControlName="place">
      </div>
    </div>


    <div class="field-combo">
      <div class="field-wrapper date-input">
        <label for="start_date">
          {{'Start' | translate}}
        </label>
        <input (focus)="startPicker.open()" [matDatepicker]="startPicker" matInput id="start_date" type="text"
               [min]="now" required
               [(ngModel)]="startDate" [ngModelOptions]="{ standalone: true }">
      </div>
      <div class="field-wrapper time-input">
        <label for="start_time">
        </label>
        <input matInput required
               id="start_time"
               type="time"
               [(ngModel)]="startTime"
               [ngModelOptions]="{ standalone: true }"
               [disabled]="!startDate"
        >
      </div>
    </div>

    <div class="field-combo">
      <div class="field-wrapper">
        <label for="end_date">
          {{'End' | translate}}
        </label>
        <input (focus)="endPicker.open()"
               [min]="startDate"
               [matDatepicker]="endPicker"
               matInput
               id="end_date" type="text"
               [(ngModel)]="endDate"
               [ngModelOptions]="{ standalone: true }"
               [disabled]="!startDate || this.form.get('allDay')?.value"
               required
        >
      </div>
      <div class="field-wrapper time-input">
        <label for="end_time">
        </label>
        <input matInput required
               id="end_time"
               type="time"
               [(ngModel)]="endTime"
               [ngModelOptions]="{ standalone: true }"
               [disabled]="!startDate || !endDate || this.form.get('allDay')?.value"
        >
      </div>
    </div>

    <div class="field-combo">
      <div class="field-wrapper all-day-input">
        <mat-checkbox formControlName="allDay" id="all_day"></mat-checkbox>
        <label for="all_day" class="allday-label">
          {{'All day' | translate}}
        </label>
      </div>
    </div>

      <div class="field-wrapper">
          <label for="employee" style="width: calc(100% - 4rem); margin-left: 2rem;">
              {{ 'Employee' | translate }}
          </label>
          <mat-select formControlName="employeeId" id="employee" style="width: calc(100% - 4rem);">
              <mat-option *ngFor="let employee of employees" [value]="employee.id">
                  {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
          </mat-select>
      </div>

      <div class="client-wrapper">
      <div class="client-information">
        <div class="title">
          <label>{{'Client' | translate }}</label>

          <div class="actions" *ngIf="form.get('firstname')?.value">
            <button mat-stroked-button (click)="editClientClicked()">{{'Change' | translate}}</button>
            <button mat-flat-button (click)="removeClientClicked()">{{'Remove' | translate}}</button>
          </div>
        </div>

        <div class="client-data" *ngIf="form.get('firstname')?.value">
          <div class="row">
            <strong
                class="full-name">{{form.get('firstname')?.value}} {{form.get('lastname')?.value}}</strong>
          </div>

          <div class="row">
            <strong class="email">{{form.get('email')?.value}}</strong>
            <span class="phone">{{form.get('phone')?.value}}</span>
          </div>
        </div>

        <div class="client-search-wrapper" *ngIf="!form.get('firstname')?.value">
          <input type="text"
                 [formControl]="searchControl"
                 [matAutocomplete]="clientAutoComplete"
                 autocomplete="chrome-off"
                 [matAutocompleteDisabled]="false"
                 #trigger="matAutocompleteTrigger">

          <mat-autocomplete
              class="autocomplete-new-client"
              panelWidth="506px" 
              #clientAutoComplete="matAutocomplete"
              (optionSelected)="patchContact($event.option.value)">

            <div class="add-new" (click)="addNewClientClicked()">
              <button mat-stroked-button>{{'Add new client' | translate}}</button>
            </div>

            <mat-option *ngFor="let contact of contacts" [value]="contact">
              <div class="client-preview">
                <strong class="full-name">{{contact.name}}</strong>
                <span class="email">{{contact.email}}</span>
                <span class="actions"><i class="icon-plus color-base-5"></i></span>
              </div>
            </mat-option>

            <mat-option disabled class="loading" *ngIf="loadingClients">
              <mat-spinner diameter="35"></mat-spinner>
            </mat-option>

            <mat-option 
              class="no-results-wrapper" 
              disabled
              *ngIf="contacts.length === 0 && !loadingClients && !searchControl.pristine">
              <span>{{'No user found' | translate}}</span>
            </mat-option>


            <mat-option disabled class="loading" *ngIf="searchControl.pristine">
            </mat-option>

          </mat-autocomplete>

          <div class="actions">
            <button type="button" mat-stroked-button (click)="showClientList()">{{'Search' | translate}}</button>
          </div>
        </div>

      </div>
    </div>

      <div class="field-wrapper">
          <label for="notes" style="width: calc(100% - 4rem); margin-left: 2rem; overflow-y: auto;">
              {{ 'Notes' | translate }}
          </label>
          <textarea formControlName="notes" id="notes" matInput style="width: calc(100% - 4rem);"></textarea>
      </div>

  </div>

  <div class="actions-wrapper">
    <button mat-stroked-button (click)="cancelClicked()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button [disabled]="form.invalid"
            (click)="saveClicked()">{{ 'Save' | translate }}</button>
  </div>
</div>

<mat-datepicker #startPicker></mat-datepicker>
<mat-datepicker #endPicker></mat-datepicker>
<app-loading *ngIf="loading"></app-loading>
